import React, { useEffect } from 'react';

function IubendaCookieConsent() {
  useEffect(() => {
    const scriptUrl = "https://cdn.iubenda.com/iubenda.js";
    const scriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    document.body.appendChild(scriptElement);

    scriptElement.onload = () => {
      // Inizializzazione del banner di Iubenda con i tuoi ID specifici
      window._iub.csConfiguration = {
        "consentOnContinuedBrowsing": false,
        "lang": "it",
        "siteId": 11078590,
        "cookiePolicyId": 11078590,
        "banner": {
          "position": "top",
          "acceptButtonDisplay": true,
          "customizeButtonDisplay": true,
          "rejectButtonDisplay": true
        }
      };
    };

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  return null; // Nessun output visuale per questo componente
}

export default IubendaCookieConsent;
