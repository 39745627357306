import React, { useState, useEffect } from 'react';

const RhymeSuggestions = ({ word, onSelectRhyme }) => {
    const [rhymes, setRhymes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [iteration, setIteration] = useState(0); // Aggiunto per forzare la variabilità

    useEffect(() => {
        setLoading(true);
        setError('');

        // Definisci un array di argomenti più ampio
        const fetchRhymes = async () => {
            try {
                // Richiesta per un numero maggiore di rime
                const response = await fetch(`https://api.datamuse.com/words?rel_rhy=${word}&max=50`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch rhymes. Status: ${response.status}`);
                }
                const data = await response.json();

                // Introduce una logica per "paginare" manualmente attraverso i risultati
                const paginatedData = data.slice(10 * iteration, 10 * (iteration + 1));
                setRhymes(paginatedData.length > 0 ? paginatedData : data.slice(0, 10)); // Se non ci sono abbastanza risultati, riparte dall'inizio
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to load rhymes. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        if (word) {
            fetchRhymes();
        }
    }, [word, iteration]); // Includi 'iteration' nelle dipendenze per rieseguire useEffect quando cambia

    const handleGetNewRhymes = () => {
        setIteration(prevIteration => prevIteration + 1);
    };

    return (
        <div>
            <button onClick={handleGetNewRhymes} disabled={loading}>
                Get New Rhymes
            </button>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                rhymes.length > 0 && (
                    <ul>
                        {rhymes.map((rhyme, index) => (
                            <li key={index} onClick={() => onSelectRhyme(rhyme.word)} style={{ cursor: 'pointer' }}>
                                {rhyme.word}
                            </li>
                        ))}
                    </ul>
                )
            )}
        </div>
    );
};

export default RhymeSuggestions;
