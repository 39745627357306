import './EmailModal.css';

function EmailModal({ onClose }) {
  return (
    <div className="email-modal">
      <div className="email-modal-header">
        <h2>Invia un'email</h2>
        <button className="email-modal-close-btn" onClick={onClose}>X</button>
      </div>
      <form className="email-modal-form" action="mailto:barsmaker@gmail.com" method="post" enctype="text/plain">
        <label htmlFor="subject">Oggetto:</label>
        <input type="text" id="subject" name="subject" />
        <label htmlFor="message">Messaggio:</label>
        <textarea id="message" name="message"></textarea>
        <button type="submit">Invia Email</button>
      </form>
    </div>
  );
}

export default EmailModal;
