import React, { useEffect, useRef } from 'react';
import './Header.css';
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

// Ensure GSAP plugins are registered
gsap.registerPlugin(MotionPathPlugin);

function Header() {
  const titleRef = useRef([]);
  const versionRef = useRef(null);
  const svgRef = useRef(null); // Ref for the SVG containing dynamic objects

  useEffect(() => {
    const svgEl = svgRef.current;

    // Define the radial gradient for the dynamic glowing object
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    const radialGradient = document.createElementNS('http://www.w3.org/2000/svg', 'radialGradient');
    radialGradient.setAttribute('id', 'glowGradient');
    const start = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
    start.setAttribute('offset', '0%');
    start.setAttribute('stop-color', '#ffffff'); // Inner color
    const end = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
    end.setAttribute('offset', '100%');
    end.setAttribute('stop-color', '#ff5722'); // Outer color
    radialGradient.appendChild(start);
    radialGradient.appendChild(end);
    defs.appendChild(radialGradient);
    svgEl.appendChild(defs);

    // Function to animate the dynamic glowing object
    const animateDynamicObject = () => {
      // Determine random side for the object to enter from
      const fromLeft = Math.random() > 0.5;
      const startX = fromLeft ? -50 : svgEl.clientWidth + 50; // Start outside the view
      const endX = fromLeft ? svgEl.clientWidth + 50 : -50; // End outside the view

      const dynamicObject = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      dynamicObject.setAttribute('cx', startX);
      dynamicObject.setAttribute('cy', Math.random() * svgEl.clientHeight);
      dynamicObject.setAttribute('r', 20);
      dynamicObject.setAttribute('fill', 'url(#glowGradient)');
      svgEl.appendChild(dynamicObject);

      // Animate object across the header
      gsap.to(dynamicObject, {
        cx: endX,
        duration: 10,
        ease: "none",
        onComplete: () => {
          dynamicObject.remove(); // Remove the object after animation
          // Schedule next animation with random delay
          setTimeout(animateDynamicObject, 2000 + Math.random() * 28000);
        }
      });
    };

    // Initial animation for the text "Bars Maker 0.4"
    gsap.fromTo([titleRef.current, versionRef.current], {
      opacity: 0,
      scale: 0.5,
      y: -50
    }, {
      opacity: 1,
      scale: 1,
      y: 0,
      stagger: 0.05,
      duration: 2,
      ease: "elastic.out(1, 0.75)"
    });

    // Start the animation of the dynamic object
    animateDynamicObject();
  }, []);

  return (
    <header className="header">
      <svg ref={svgRef} width="100%" height="100%"></svg>
      <div className="header-content">
        <div className="title-3d">
          {"BarsMaker".split("").map((char, index) => (
            <span key={index} ref={el => (titleRef.current[index] = el)}>{char}</span>
          ))}
          <span ref={el => (versionRef.current = el)} className="version">0.4</span>
        </div>
        <h1>Write your Bars and Create Music</h1>
      </div>
    </header>
  );
}

export default Header;
