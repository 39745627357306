import React, { useState, useEffect } from 'react';
import './BarsManager.css';
import { jsPDF } from 'jspdf';
import RhymeSuggestions from '../components/RhymeSuggestions'; // Assicurati che il percorso sia corretto

function BarsManager() {
  const [bars, setBars] = useState([]);
  const [newBar, setNewBar] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [showRhymes, setShowRhymes] = useState(false);
  const [currentTrack, setCurrentTrack] = useState({
    youtube: '',
    soundCloud: '',
    uploaded: ''
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [saves, setSaves] = useState(() => {
    const savedSaves = localStorage.getItem('barsSaves');
    return savedSaves ? JSON.parse(savedSaves) : [];
  });

  // Mostra un prompt di conferma prima di procedere con l'eliminazione
  const handleDeleteSave = (indexToDelete) => {
    const isConfirmed = window.confirm("Do you really want to delete your bars?");
    if (isConfirmed) {
      const updatedSaves = saves.filter((_, index) => index !== indexToDelete);
      setSaves(updatedSaves);
      localStorage.setItem('barsSaves', JSON.stringify(updatedSaves));
    }
  };

  useEffect(() => {
    localStorage.setItem('barsSaves', JSON.stringify(saves));
  }, [saves]);

  const addBar = () => {
    if (newBar.trim() !== '') {
      setBars([...bars, newBar.trim()]);
      setNewBar('');
      setShowRhymes(false); // Nasconde i suggerimenti di rima dopo l'aggiunta
    }
  };

  const startEditing = (index) => {
    setEditingIndex(index);
    setEditingText(bars[index]);
  };

  const saveEdit = (index) => {
    const updatedBars = bars.map((bar, i) => (i === index ? editingText.trim() : bar));
    setBars(updatedBars);
    setEditingIndex(null);
    setEditingText('');
  };

  const handleSaveBars = () => {
    const title = prompt('Please enter a title for your track:');
    if (title) {
      const newSave = {
        title,
        currentTrack,
        bars: bars.join("\n"),
        dateTime: new Date().toLocaleString(),
      };
      setSaves([...saves, newSave]);
    }
  };

  const handleLoadSave = (save) => {
    setBars(save.bars.split("\n"));
    setCurrentTrack(save.currentTrack);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleExportSave = (save, format) => {
    const content = `Title: ${save.title}\n\n${save.bars}`;
    // La logica per l'esportazione rimane invariata...
  };

  // Gestore per input, incluso mostrare suggerimenti di rime
  const handleBarInput = (e) => {
    setNewBar(e.target.value);
    if (e.target.value.trim().split(" ").pop() !== currentWord) {
      setShowRhymes(false); // Resetta i suggerimenti di rima se la parola cambia
    }
  };

  const handleRhymeButtonClick = () => {
    if (newBar.trim() !== '') {
      const lastWord = newBar.trim().split(" ").pop();
      setCurrentWord(lastWord);
      setShowRhymes(true);
    }
  };

  const addRhymeToBar = (rhyme) => {
    setNewBar(newBar + " " + rhyme);
    setShowRhymes(false); // Opzionale: nascondere i suggerimenti di rima dopo la selezione
  };
  
  const handleSelectRhyme = (rhyme) => {
    // Aggiunge la rima selezionata alla fine del testo corrente
    setNewBar(prev => `${prev} ${rhyme}`);
    setShowRhymes(false); // Nasconde i suggerimenti di rima dopo la selezione
  };

  return (
    <div className="container">
      <h1>Write your Bars</h1>
      <div className="input-container">
        <input
          type="text"
          value={newBar}
          onChange={handleBarInput}
          onKeyPress={(e) => e.key === 'Enter' && addBar()}
          placeholder="Write your Bars here..."
          maxLength="300"
        />
        <button onClick={addBar}>🔫</button>
        <button onClick={handleRhymeButtonClick} className="rhyme-suggestion-button">
          📖
        </button>
      </div>
      {showRhymes && (
      <RhymeSuggestions word={currentWord} onSelectRhyme={handleSelectRhyme} />
    )}
      <ul className='ulli'>
        {bars.map((bar, index) => (
          <li key={index} onTouchStart={() => startEditing(index)} className="bar-container">
            {editingIndex === index ? (
              <input
                type="text"
                value={editingText}
                onChange={(e) => setEditingText(e.target.value)}
                onBlur={() => saveEdit(index)}
                onKeyPress={(e) => e.key === 'Enter' && saveEdit(index)}
                autoFocus
                className="editing-input"
              />
            ) : (
              <span onClick={() => startEditing(index)}>{bar}</span>
            )}
          </li>
        ))}
      </ul>
      <div className="save-bars-button-container">
        <button onClick={handleSaveBars}>Save Bars</button>
      </div>
      <div className="your-bars-section">
        <button onClick={toggleDropdown} className="dropdown-toggle">
          Your Bars
        </button>
        {dropdownOpen && (
          <ul className="dropdown-menu">
            {saves.map((save, index) => (
              <li key={index}>
                <button onClick={() => handleLoadSave(save)}>
                  {save.title}
                </button>
                <span onClick={() => handleDeleteSave(index)} className="delete-icon"><i className="fa fa-trash" aria-hidden="true"></i></span>
                <button onClick={() => handleExportSave(save, 'txt')} className="export-btn">
                  txt
                </button>
                <button onClick={() => handleExportSave(save, 'pdf')}>PDF</button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default BarsManager;
