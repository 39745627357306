import React, { useState } from 'react';
import EmailModal from './EmailModal'; // Assumi che questo sia il tuo componente modal per l'email

function Footer() {
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);

  const handleEmailClick = (e) => {
    e.preventDefault();
    setEmailModalOpen(true);
  };

  return (
    <footer>
      <div className="footer-content">
        <p>&copy; 2023 Bars Maker. All rights reserved.</p>
        <p>Contact us: <a href="#!" onClick={handleEmailClick}>barsmaker@gmail.com</a></p>
        <p>Designed by FurbsLoops</p>
      </div>
      <div className="privacycontents">
        <div className="privacyp">
          <a
            href="https://www.iubenda.com/privacy-policy/11078590"
            className="iubenda-white iubenda-embed"
            title="Privacy Policy"
          >
            Privacy Policy
          </a>
        </div>
        <div className="cookiep">
          <a
            href="https://www.iubenda.com/privacy-policy/11078590/cookie-policy"
            className="iubenda-white iubenda-embed"
            title="Cookie Policy"
          >
            Cookie Policy
          </a>
        </div>
        <div className="termsp">
          <a
            href="https://www.iubenda.com/termini-e-condizioni/11078590"
            className="iubenda-white iubenda-embed"
            title="Termini e Condizioni"
          >
            Termini e Condizioni
          </a>
        </div>
      </div>
      {isEmailModalOpen && <EmailModal onClose={() => setEmailModalOpen(false)} />}
    </footer>
  );
}

export default Footer;
