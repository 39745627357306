import React from 'react';
import Header from './components/Header'
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';

import './components/TermCond.css'; // Assicurati di importare il CSS dell'app

function PrivacyPolicy() {
  return (
    <div className="App">
      
      <Header />
      <div className="privacy-policy-container">
    
        <h1>Terms and Conditions</h1>
        <li>
          Welcome to www.barsmaker.com!

          These terms and conditions outline the rules and regulations for the use of the Bars Maker Website, located at www.barsmaker.com.

          By accessing this website we assume you accept these terms and conditions. Do not continue to use Bars Maker if you do not agree to take all of the terms and conditions stated on this page.
        </li>
        <h2>Cookies</h2>
        <li>
          The site uses cookies to help personalize your online experience and to enhance your navigation of the website. By using our website, you consent to the use of cookies in accordance with Bars Maker's privacy policy.
        </li>
        <h2>License</h2>
        <li>
          Unless otherwise stated, Bars Maker and/or its licensors own the intellectual property rights for all material on Bars Maker. All intellectual property rights are reserved. You may access this from Bars Maker for your personal use subjected to restrictions set in these terms and conditions.
        </li>
        <h2>You must not:</h2>
        <li>
          Copy or republish material from Bars Maker
        </li>
        <li>
          Sell, rent or sub-license material from Bars Maker
        </li>
        <li>
          Reproduce, duplicate or copy material from Bars Maker
        </li>
        <li>
          Redistribute content from Bars Maker
        </li>
        <li>
          Parts of this site offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Bars Maker does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Bars Maker, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Bars Maker shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
        </li>
        <li>
          Bars Maker reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
        </li>
        </div>
        <div className='Disclaimer'>
        <h2>Disclaimer</h2>
        <li>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
        </li>

        <li>
          limit or exclude our or your liability for death or personal injury;
          limit or exclude our or your liability for fraud or fraudulent misrepresentation;
          limit any of our or your liabilities in any way that is not permitted under applicable law;
          exclude any of our or your liabilities that may not be excluded under applicable law.</li>


        <Footer />
        <IubendaCookieConsent />
        {/* Inserisci qui i bottoni di Iubenda se necessario */}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
