import React, { useEffect } from 'react';
import './CustomCookieConsent.css';

function CustomCookieConsent() {
  useEffect(() => {
    // Crea uno script element per Cookie Consent
    const scriptElement = document.createElement('script');
    scriptElement.src = "https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js";
    scriptElement.async = true;

    // Aggiunge lo script al body
    document.body.appendChild(scriptElement);

    scriptElement.onload = () => {
      window.cookieconsent.initialise({
        palette: {
          popup: { background: "#000" },
          button: { background: "#f1d600" },
        },
        position: "bottom-right",
        content: {
          message: "Questo sito utilizza i cookie per garantire la migliore esperienza sul nostro sito.",
          dismiss: "Accetto",
          link: "Scopri di più",
          href: "https://www.iubenda.com/privacy-policy/11078590/cookie-policy"
        }
      });
    };

    // Pulizia: rimuove lo script quando il componente viene smontato
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  return null; // Nessun output visuale per questo componente
}

export default CustomCookieConsent;
