import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';
import BuyMeACoffeeButton from './components/BuyMeACoffeeButton';
import './App.css'; // Assicurati di importare il CSS dell'app

function Service() {
  return (
    <div className="App">
      <Header />
      <BuyMeACoffeeButton />
      {/* Qui potresti voler personalizzare l'header per la pagina del servizio, ad esempio: */}
      {/* <Header title="Servizi" subtitle="Scopri i nostri servizi." /> */}
      <Footer />
      <IubendaCookieConsent />
      {/* Inserisci qui i bottoni di Iubenda se necessario */}
    </div>
  );
}

export default Service;
