import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';
import './App.css'; // Assicurati di importare il CSS dell'app

function Freestyle() {
  return (
    <div className="App">
      <Header />
      <h1>Freestyle Wall</h1>
      <li> <h2>Soon you will be able to share <br></br>bars or your tracks publicly <br></br> on the BarsMaker dashboard
        </h2></li>
      <Footer />
      <IubendaCookieConsent />
      {/* Inserisci qui i bottoni di Iubenda se necessario */}
    </div>
  );
}

export default Freestyle;
