import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import NavBar from './components/NavBar';
import Header from './components/Header';
import BarsManager from './components/BarsManager';
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';
import './App.css';
import AudioUploaderPlayer from './components/AudioUploaderPlayer';
import BuyMeACoffeeButton from './components/BuyMeACoffeeButton';
import AudioRecorder from './components/AudioRecorder';
// Importazioni delle pagine create
import Service from './Service';
import PrivacyPolicy from './TermCond';
import Partners from './Partners';
import Freestyle from './Freestyle';
import BarsNotorious from './BarsNotorious';
import NavBar from './components/UI/Navbar/NavBar'
import CustomCookieConsent from './CustomCookieConsent';

//DONE: Libreria manipolabile e integrazione autotune ed effetti: https://github.com/alexcrist/autotone
//TODO: integrazione rimario in BarsManager
//TODO: layout sfogliabile componenti
//TODO: pagina utente con condivisione tracce e collezione basi
//TODO: dashboard condivisione tracce limitabile 
//TODO: autenticazione 
//TODO: rendering montaggio traccia con traccia base e record vocale
//TODO: sistema token per ottenere beats e premi e retribuzione producers 

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Rotta principale/Home */}
          <Route path="/" element={
            <>
              <CustomCookieConsent />
              <Header />
              <AudioUploaderPlayer />
              <BarsManager />
              <AudioRecorder />
              <Footer />
              <IubendaCookieConsent />
            </>
          } />
          {/* Rotte per le pagine aggiuntive */}
          <Route path="/service" element={<Service />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/freestyle" element={<Freestyle />} />
          <Route path="/BarsNotorious" element={<BarsNotorious />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
