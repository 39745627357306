import React, { useState, useEffect } from 'react';
import RecordRTC from 'recordrtc';
import { openDB } from 'idb'; 
import './AudioRecorder.css';
import './AudioRecorderList.css';

const useStoredRecordings = () => {
    const [recordings, setRecordings] = useState(() => {
        const storedRecordings = localStorage.getItem('audioRecordings');
        return storedRecordings ? JSON.parse(storedRecordings) : [];
    });

    useEffect(() => {
        localStorage.setItem('audioRecordings', JSON.stringify(recordings));
    }, [recordings]);

    return [recordings, setRecordings];
};

// IndexedDB setup and operations
const initDB = async () => {
    const db = await openDB('audioRecordingsDB', 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains('recordings')) {
                db.createObjectStore('recordings', { keyPath: 'id' });
            }
        },
    });
    return db;
};

const saveRecordingBlob = async (blob, id) => {
    const db = await initDB();
    await db.put('recordings', { id, blob });
};

const getRecordingBlob = async (id) => {
    const db = await initDB();
    const recording = await db.get('recordings', id);
    return recording ? recording.blob : null;
};

const deleteRecordingBlob = async (id) => {
    const db = await initDB();
    await db.delete('recordings', id);
};

const AudioRecorder = () => {
    const [recordAudio, setRecordAudio] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [showRecordingsList, setShowRecordingsList] = useState(false);
    const [recordings, setRecordings] = useStoredRecordings();
    const [audioUrls, setAudioUrls] = useState({});

    useEffect(() => {
        return () => recordAudio && recordAudio.stopRecording();
    }, [recordAudio]);

    useEffect(() => {
        const fetchBlobs = async () => {
            const urls = {};
            for (const recording of recordings) {
                const blob = await getRecordingBlob(recording.id);
                if (blob) {
                    urls[recording.id] = URL.createObjectURL(blob);
                }
            }
            setAudioUrls(urls);
        };

        fetchBlobs();
    }, [recordings]);

    const startRecording = async () => {
        try {
            setIsRecording(true);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/webm;codecs=opus', // Adjust for high quality audio
                numberOfAudioChannels: 2, // Stereo audio recording
            });
            recorder.startRecording();
            setRecordAudio(recorder);
        } catch (error) {
            // Gestisci l'errore qui, ad esempio informando l'utente del problema
            setIsRecording(false);
            console.error('Access to the microphone was denied!', error);
            alert('Recording cannot proceed because the microphone access was denied.');
        }
    };
    

    const stopRecording = () => {
        if (recordAudio) {
            recordAudio.stopRecording(async () => {
                const audioBlob = recordAudio.getBlob();
                const id = new Date().toISOString();
                await saveRecordingBlob(audioBlob, id);

                const title = prompt("Please enter a title for your recording:");
                if (title) {
                    const newRecording = {
                        id,
                        title,
                        dateTime: new Date().toLocaleString(),
                    };
                    setRecordings(prevRecordings => [...prevRecordings, newRecording]);
                }

                setIsRecording(false);
                // Refresh the recordings list without reloading the page
                setAudioUrls(prevUrls => ({...prevUrls, [id]: URL.createObjectURL(audioBlob)}));
            });
        }
    };


    const deleteRecording = async (idToDelete) => {
        await deleteRecordingBlob(idToDelete);
        const updatedRecordings = recordings.filter(({ id }) => id !== idToDelete);
        setRecordings(updatedRecordings);
    };

    const downloadRecording = (id, title) => {
        // Ensure the blob URL is ready before allowing download
        if (audioUrls[id]) {
            const link = document.createElement('a');
            link.href = audioUrls[id];
            link.download = `${title}.webm`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div className="component-container">
            <div className="audio-recorder-container">
                <h1>Rec your Bars</h1>
                <div className="recording-controls">
                    <button onClick={startRecording} disabled={isRecording} className={`start-recording ${isRecording ? "recording" : ""}`}>
                        Start Recording
                    </button>
                    <button onClick={stopRecording} disabled={!isRecording} className={`stop-recording ${isRecording ? "" : "pressed"}`}>
                        Stop Recording
                    </button>
                </div>
                <button onClick={() => setShowRecordingsList(!showRecordingsList)} className="toggle-recordings-btn">
                    {showRecordingsList ? 'Hide' : 'Show'} Recordings
                </button>
                {showRecordingsList && (
                    <div className="recordings-list">
                        {recordings.map(({ id, title }) => (
                            <div key={id} className="recording-item">
                                <span>{title}</span>
                                {audioUrls[id] ? <audio src={audioUrls[id]} controls /> : null}
                                <div className="recbuttons">
                                    <button onClick={() => downloadRecording(id, title)}>⬇️</button>
                                    <button onClick={() => deleteRecording(id)}>🗑️</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AudioRecorder;