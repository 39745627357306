import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';
import './App.css'; // Assicurati di importare il CSS dell'app

function Partners() {
  return (
    <div className="App">
      <Header />
      <h1>Collaborate with us</h1>
      <li> <h2>Our project is only just beginning! <br></br>
        Contact us by email</h2></li>
      <Footer />
      <IubendaCookieConsent />
      {/* Inserisci qui i bottoni di Iubenda se necessario */}
    </div>
  );
}

export default Partners;
