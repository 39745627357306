import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import IubendaCookieConsent from './components/IubendaCookieConsent';
import './components/BarsNotorious.css'; // Assicurati di importare il CSS dell'app


function BarsNotorious() {
  return (
    <div className="App">
      <Header />
      <h1>Bars Notorious</h1>
      <li> <h2>Gems of the underground scene selected by BarsMakers
        </h2></li>
      <Footer />
      <IubendaCookieConsent />
      {/* Inserisci qui i bottoni di Iubenda se necessario */}
    </div>
  );
}

export default BarsNotorious;
