import React from 'react';
import './BuyMeACoffeeButton.css'; // Assicurati di creare questo file CSS

function BuyMeACoffeeButton() {
    const handleClick = () => {
        window.open('https://www.buymeacoffee.com/BarsMaker', '_blank');
    };

    return (
        <button onClick={handleClick} className="buy-me-a-coffee-btn">
            Donation 😮
        </button>
    );
}

export default BuyMeACoffeeButton;
