import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import TextField from '@mui/material/TextField'
import './AudioUploaderPlayer.css';
import Input from '@mui/material/Input';
import LinkIcon from '@mui/icons-material/Link';
import InputAdornment from '@mui/material/InputAdornment';

const AudioUploaderPlayer = () => {
  const [url, setUrl] = useState('');
  const [file, setFile] = useState(null);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setFile(null); // Resetta il file quando si inserisce un URL
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      const audioUrl = URL.createObjectURL(files[0]);
      setFile(files[0]);
      setUrl(audioUrl); // Imposta l'URL per ReactPlayer
    }
  };

  return (
    <div className="audio-uploader-container">
      <h1>Upload a Beat <br></br>from Youtube or Soundcloud</h1>

      <Input
        id="outlined-basic"
        label="Inserisci un URL di YouTube o SoundCloud" variant="outlined" value={url} onChange={handleUrlChange} fullWidth={true}
        startAdornment={
          <InputAdornment position="start">
          </InputAdornment>
        }
      />
    <h1>Upload your beat</h1>
      {/* Associa il label all'input del file tramite l'ID */}
      <input
        id="file-upload"
        type="file"
        accept="audio/mpeg, audio/wav, audio/ogg"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />


      <label htmlFor="file-upload" className="custom-file-upload">
        Upload
      </label>
      {url && (
        <ReactPlayer
          url={url}
          playing={true}
          controls={true}
          width="98%"
          height="auto" // L'altezza si adatta automaticamente al contenuto
          className="react-player"
        />
      )}
    </div>
  );
};

export default AudioUploaderPlayer;
